import React from "react";
import $ from "jquery";

import openModalLink from "../Utilities/openModalLink";

import content from "../Utilities/_content";
import logo from "../Assets/logo-white.png";
import contact from "../Assets/contact.png";

function Header(props) {
    return (
        <div className="header">
            <img
                className="contact"
                data-toggle="modal"
                data-target="#contactModal"
                src={contact}
                alt="contact"
            ></img>
            <div className="header-content">
                <img className="logo" src={logo} alt="logo"></img>
                <div className="title">{content.general.name}</div>
                <div
                    className="icon-scroll"
                    onClick={() => {
                        $("html,body").animate(
                            {
                                scrollTop: $(".body").offset().top,
                            },
                            "slow"
                        );
                    }}
                >
                    {" "}
                </div>
            </div>

            <div
                className="modal fade"
                id="contactModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="contactModal"
                aria-hidden="true"
            >
                <div
                    className="modalContent"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(event) => openModalLink(event)}
                >
                    <div className="modalText">
                        <div className="text">escriu-me a</div>
                        <a
                            className="email modalLink"
                            href="mailto:info@quirosportbalague.com?Subject=Informació"
                        >
                            info@quirosportbalague.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
