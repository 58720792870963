const content = {
    general: {
        name: "Quirosport Balague",
        phoneNumber: "123456789",
        languages: {
            catalan: "CA",
            spanish: "ES",
            english: "EN",
        },
    },
    catalan: {
        chiromassage: {
            id: "chiromassage",
            title: "Quiromassatge",
            text:
                "Gaudeix d'un agradable massatge tonificant amb propietats neurosensitives, drenants i circulatòries.",
        },
        kinesiotaping: {
            id: "kinesiotaping",
            title: "Kinesiotaping",
            text:
                "El venatge neuromuscular es una tècnica que alleuja i millora la circulació. Les cintes tensen i eleven lleugerament la pell, millorant així la circulació local i el drenatge dels teixits. \n Redueixen la tensió, descomprimeixen l'espai i disminueixen el dolor.",
        },
        personalTrainer: {
            id: "personalTrainer",
            title: "Entrenador personal",
            text:
                "Si necessites un pla d'entrenament personalitzat o algú que et faci acompanyament, no dubtis en contactar-me.",
        },
    },
    spanish: {},
    english: {},
};

export default content;
