import React from "react";
import $ from "jquery";

import content from "../Utilities/_content";
import FlipCard from "./FlipCard";

import arrow from "../Assets/upwards-arrow.png";

let bodyContent = content.catalan;

function Body(props) {
    return (
        <div className="body container-fluid">
            <div className="row">
                <FlipCard
                    id="chiromassage"
                    title={content.catalan.chiromassage.title}
                    text={bodyContent.chiromassage.text}
                />
                <FlipCard
                    id="kinesiotaping"
                    title={content.catalan.kinesiotaping.title}
                    text={bodyContent.kinesiotaping.text}
                />
                <FlipCard
                    id="personalTrainer"
                    title={content.catalan.personalTrainer.title}
                    text={bodyContent.personalTrainer.text}
                />
                <div
                    className="up-button"
                    onClick={() => {
                        $("html,body").animate(
                            {
                                scrollTop: 0,
                            },
                            "slow"
                        );
                        $(".flip-card").each((index, value) => {
                            if (
                                value.firstElementChild.style.transform !== ""
                            ) {
                                value.firstElementChild.style.transform = "";
                            }
                        });
                    }}
                >
                    <img src={arrow} alt="upwards-arrow"></img>
                </div>
            </div>
        </div>
    );
}

export default Body;
