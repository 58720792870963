import React from "react";

import Header from "./Components/Header";
import Body from "./Components/Body";
import Footer from "./Components/Footer";
import CopyrightModal from "./Components/CopyrightModal";

function App() {
    return (
        <div className="page">
            <Header />

            <Body />

            <Footer />

            <CopyrightModal />
        </div>
    );
}

export default App;
