import React from "react";

function Footer(props) {
    return (
        <div
            className="footer"
            data-toggle="modal"
            data-target="#copyrightModal"
        >
            <div className="footer-text">
                <div className="title">LEGAL NOTES & CREDITS</div>
            </div>
        </div>
    );
}

export default Footer;
