import React from "react";

import openModalLink from "../Utilities/openModalLink";

function CopyrightModal(props) {
    return (
        <div
            className="modal fade"
            id="copyrightModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="copyrightModalLabel"
            aria-hidden="true"
        >
            <div
                className="modalContent"
                onClick={(event) => openModalLink(event)}
                data-dismiss="modal"
                aria-label="Close"
            >
                <div className="copyright">
                    <div className="text">
                        copyright © jgramunt <br /> all rights reserved
                    </div>
                </div>

                <div className="credits" id="modalCredits">
                    <div>
                        Icons by&nbsp;
                        <a
                            className="modalLink"
                            href="https://www.flaticon.com/authors/hirschwolf"
                            rel="noreferrer"
                            title="hirschwolf"
                            target="_blank"
                        >
                            hirschwolf
                        </a>
                        ,&nbsp;
                        <a
                            className="modalLink"
                            href="https://www.flaticon.com/authors/good-ware"
                            rel="noreferrer"
                            title="Good Ware"
                            target="_blank"
                        >
                            Good Ware
                        </a>
                        ,&nbsp;
                        <a
                            className="modalLink"
                            href="https://www.flaticon.com/authors/freepik"
                            rel="noreferrer"
                            title="Freepik"
                            target="_blank"
                        >
                            Freepik
                        </a>
                        ,&nbsp;
                        <a
                            className="modalLink"
                            href="https://www.flaticon.com/authors/smashicons"
                            rel="noreferrer"
                            title="Smashicons"
                        >
                            Smashicons
                        </a>{" "}
                        ,&nbsp;
                        <a
                            className="modalLink"
                            href="https://www.flaticon.com/authors/pixel-perfect"
                            rel="noreferrer"
                            title="Pixel perfect"
                            target="_blank"
                        >
                            Pixel perfect
                        </a>
                        &nbsp; from{" "}
                        <a
                            className="modalLink"
                            href="https://www.flaticon.com/"
                            rel="noreferrer"
                            title="Flaticon"
                        >
                            www.flaticon.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CopyrightModal;
