import React from "react";

import flipIcon from "../Assets/flip-icon.png";

function FlipCard(props) {
    return (
        <div className="flip-card col-xs-12 col-sm-4" id={props.id}>
            <div
                className="inner"
                onClick={(e) => {
                    let transformStyle = e.currentTarget.style.transform;
                    if (transformStyle === "") {
                        e.currentTarget.style.transform = "rotateY(180deg)";
                    } else {
                        e.currentTarget.style.transform = "";
                    }
                }}
            >
                <div className="front">
                    <div className="title">{props.title}</div>
                    <img className="flip-icon" src={flipIcon} alt="logo"></img>
                </div>
                <div className="back">
                    <div className="text">{props.text}</div>
                    <div className="icons">
                        <div className="first-icon"></div>
                        <div className="second-icon"></div>
                    </div>

                    <img className="flip-icon" src={flipIcon} alt="logo"></img>
                </div>
            </div>
        </div>
    );
}

export default FlipCard;
